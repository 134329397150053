import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { Navbar, Footer, BackToSite } from "../../components";
import { DefaultGlobalStyle } from "../../styles";
import SiteDisclaimer from "../../components/SiteDisclaimer";
import {CookiesProvider} from 'react-cookie';

const Wrapper = styled.div`
  @media (min-width: 760px){
    background: url(images/uploads/bg.gif) top left repeat-x;
  }
`;

const Content = styled.div`
  max-width: 760px;
  margin: 0 auto;
  border: solid 1px #cccccc;
`;

export const DefaultLayoutComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => (
    <React.Fragment>
      <DefaultGlobalStyle />
      <Wrapper>
        <Content>
          {children}
        </Content>
      </Wrapper>
    </React.Fragment>
  );

export default ({ children }: { children: React.ReactNode }) => (
  <React.Fragment>
    <Helmet>
      <title>EEA Fund Management (Guernsey) Limited</title>
    </Helmet>
    <DefaultLayoutComponent>
      <BackToSite />
      <Navbar />
      {children}
      <Footer />
    </DefaultLayoutComponent>

    <CookiesProvider>
      <SiteDisclaimer />
    </CookiesProvider>
  </React.Fragment>
);
