import React from "react";
import styled from "@emotion/styled";

import { darkGreen, lightGreen } from "../../utils/colors";

const Wrapper = styled.section<{ image: string }>`
  width: 100%;
  height: ${(props) => (props.image ? "200px" : "auto")};
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 7px solid ${darkGreen};
  border-bottom: 7px solid ${lightGreen};
  positon: relative;
  max-width: 800px;
`;

const Box = styled.div`
  height: 100%;
  width: 30%;
  min-width: 200px;
  background: black;
  opacity: 0.8;
  position: relative;
`;

const Text = styled.h1`
  color: #ffffff;
  bottom: 0;
  font-family: Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  left: 25px;
  position: absolute;
`;

export type HeroProps = {
  showBox: boolean;
  boxText?: string;
  image: string;
};

export default ({ showBox, boxText, image }: HeroProps) => (
  <Wrapper image={image}>
    {showBox && (
      <Box>
        <Text>{boxText ? boxText : ""}</Text>
      </Box>
    )}
  </Wrapper>
);
