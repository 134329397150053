import React from 'react';
import { useCookies } from 'react-cookie';
import { Modal, Button } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const CookieName = 'siteDisclaimer';
const Content = styled.article`
  color: inherit;
`;

function SiteDisclaimer() {
    const [cookies, setCookie] = useCookies([CookieName]);
    const data = useStaticQuery(graphql`
    {
      markdown: markdownRemark(fileAbsolutePath: { regex: "/(disclaimer.md)/" }) {
        frontmatter {
          title
        }
        html
      }
    }
  `);

    function onChange() {
        setCookie(CookieName, CookieName, { path: '/' });
    }

    return (
        <Modal show={!cookies[CookieName]} animation={true} size="lg">
            <Modal.Header>
                <Modal.Title>Disclaimer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Content dangerouslySetInnerHTML={{ __html: data.markdown.html }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onChange}>
                    Accept
                    </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SiteDisclaimer;