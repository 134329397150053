import React from "react";
import styled from "@emotion/styled/macro";
import { Link } from "gatsby";

const Box = styled((props) => <Link {...props} />)`
  box-shadow: 0px 0px 6px rgba(50, 50, 50, 0.85);
  border: 3px solid #ffffff;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

const Title = styled.h3<{ color: string }>`
  border: solid 2px ${(props) => props.color};
  padding: 4px 10px;
  background-color: ${(props) => props.color};
  font-weight: normal;
  font-size: 1.1rem;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 0 1px 0;
`;

const Image = styled.div<{ image: string }>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 65px;
`;

const Description = styled.p`
  border: solid 1px #dddddd;
  border-top: none;
  font-size: 0.9rem;
  line-height: 16px;
  color: #666666;
  padding: 11px;
  margin: 0;
  flex-grow: 1;
`;

export const MultiProductBoxWrapper = styled.div`
  padding: 25px 10px;
  display: flex;
  flex-wrap: wrap;
  & ${Box} {
    flex: 1;
    flex-basis: 150px;
    width: 100%;
    margin: 0 5px 15px 5px;
  }
`;

export type ProductBoxProps = {
  color: string;
  title: string;
  image: string;
  description: string;
  slug: string;
};

export default ({
  color,
  title,
  image,
  description,
  slug,
}: ProductBoxProps) => (
  <Box to={slug}>
    <Title color={color}>{title}</Title>
    <Image image={image}></Image>
    <Description>{description}</Description>
  </Box>
);
