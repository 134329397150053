import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  width: 100%;
  background-color: #e5e7ea;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Link = styled.a`
  color: #693;
  text-decoration: none;
  font-size: 0.9rem;
  &:hover,
  &:focus {
    color: #f90;
  }
`;

export default () => (
  <Wrapper>
    <Link href="http://www.eeafm.com/">« EEA FM UK website</Link>
  </Wrapper>
);
