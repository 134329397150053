import React from "react";
import { Global, css } from "@emotion/core";

const styles = css`
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
    color: #666;
    font-size: 13px;
  }
  p {
    line-height: 18px;
  }
  a {
    text-decoration: none;
    color: #693;
  }
  h1 {
    font-size: 28px;
    line-height: 28px;
    margin: 0 0 5px;
    color: #360;
  }
`;

const DefaultGlobalStyle = () => <Global styles={styles} />;

export default DefaultGlobalStyle;
