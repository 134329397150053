import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

export const Body = styled.article`
  display: flex;
  flex: 2;
  flex-direction: column;
  padding: 30px 25px;
  background: white;
  & h1 {
    font-size: 28px;
    line-height: 28px;
    color: #336600;
    font-weight: normal;
  }
  & h2 {
    font-size: 22px;
    line-height: 24px;
    color: #336600;
    font-weight: normal;
  }
  & h3 {
    font-size: 18px;
    line-height: 22px;
    color: #666666;
  }
  & hr {
    opacity: 0.4;
    margin: 20px 0;
  }
`;

const Sidebar = styled.aside`
  background-color: #f9f8ee;
  border-left: solid 1px #cccccc;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  & > div,
  & > a {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export type SidebarLayoutProps = {
  body: React.ReactNode;
  sidebar: React.ReactNode;
};

export default ({ body, sidebar }: SidebarLayoutProps) => (
  <Wrapper>
    {body}
    <Sidebar>{sidebar}</Sidebar>
  </Wrapper>
);
