import React from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import { graphql, useStaticQuery, Link } from "gatsby";

import { lightGreen, darkGreen } from "../../utils/colors";

const mobileBreakpoint = 560;

const Wrapper = styled.nav`
  width: 100%;
  font-family: Helvetica, sans-serif;
  display: flex;
  height: 150px;
  padding: 10px 0;
  align-items: center;
  background: white;
  @media (max-width: ${mobileBreakpoint}px) {
    padding-right: 45px;
  }
`;

const Logo = styled(props => <Link {...props} />)`
  flex: 1;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 15px;
  height: 100%;
`;

const List = styled.ul<{ nested: boolean }>`
  padding: 0;
  margin: 0;
  list-style: none;
  /* Desktop Style */
  @media (min-width: ${mobileBreakpoint}px) {
    ${(props) =>
    props.nested
      ? css`
            position: absolute;
            padding: 0 5px 0 15px;
            display: none;
            height: 100%;
            min-height: 100px;
            max-height: 100px;
            flex-direction: column;
            justify-content: space-between;
            background-color: #ffffff;
            z-index: 3;
            & li {
              text-align: left;
              border: none;
            }
            & li a {
              height: 100%;
              justify-content: flex-start;
            }
          `
      : css`
            height: 100%;
            position: relative;
            display: flex;
            flex: 2;
          `};
  }
  /* Mobile Style */
  @media (max-width: ${mobileBreakpoint - 1}px) {
    width: 100%;
    background-color: white;
    display: none;
    flex-direction: column;
    padding-left: 10px;
    ${(props) =>
    props.nested
      ? css`
            display: flex;
            position: relative;
            padding-left: 25px;
          `
      : css`
            top: 150px;
            position: absolute;
            border-bottom: 1px solid #cccccc;
          `}
  }
`;

const ListItem = styled.li`
  border-left: 1px solid #cccccc;
  height: 100%;
  flex: 1;
  &:hover > ul {
    display: flex;
  }
  @media (max-width: ${mobileBreakpoint}px) {
    margin: 8px 0;
  }
`;

const LinkCSS = css`
  align-items: center;
  color: #555555;
  display: inline-flex;
  font-size: 0.8rem;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  &:hover {
    color: #669933;
    text-decoration: underline;
  }
  @media (max-width: ${mobileBreakpoint}px) {
    padding: 10px 20px;
    justify-content: flex-start;
  }
`;

const MobileMenuCover = styled.div`
  width: 35px;
  height: 35px;
  position: absolute;
  right: 15px;
  display: none;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  pointer-events: none;
  & div {
    width: 100%;
    height: 4px;
    background-color: ${darkGreen};
  }
  @media (max-width: ${mobileBreakpoint}px) {
    display: flex;
  }
`;

const MenuButton = styled.input`
  display: none;
  width: 35px;
  height: 35px;
  margin: 0;
  right: 0;
  cursor: pointer;
  margin: 15px;
  position: absolute;
  opacity: 0;
  &:checked ~ ${List} {
    display: flex;
  }
  &:checked ~ ${MobileMenuCover} {
    background: white;
    outline: solid 4px ${lightGreen};
  }
  @media (max-width: ${mobileBreakpoint}px) {
    display: block;
  }
`;

const InternalLink = styled((props) => <Link {...props} />)`
  ${LinkCSS}
`;

const ExternalLink = styled.a`
  ${LinkCSS}
`;

const LinkDecider = ({
  url,
  text,
  isExternal,
}: {
  url: string;
  text: string;
  isExternal: boolean;
}) => {
  return isExternal ? (
    <ExternalLink target="_blank" rel="noopener" href={url}>
      {text}
    </ExternalLink>
  ) : (
      <InternalLink to={url}>{text}</InternalLink>
    );
};

const NestedLinks = ({
  isExternal,
  nestedLinks,
}: {
  isExternal: boolean;
  nestedLinks: {
    text: string;
    url: string;
    isExternal: boolean;
  }[];
}) => (
    <List nested={true}>
      {nestedLinks.map((link, index) => (
        <ListItem key={index}>
          <LinkDecider {...link} />
        </ListItem>
      ))}
    </List>
  );

type Link = {
  text: string;
  url: string;
  isExternal: string;
};

export type NavbarProps = {
  logo: string;
  links: {
    nestedLinks?: Link[];
  }[] &
  Link[];
};

export const NavbarComponent = ({ logo, links }: NavbarProps) => (
  <Wrapper>
    <Logo image={logo} to="/" />
    <MenuButton type="checkbox" />
    <MobileMenuCover>
      <div />
      <div />
      <div />
    </MobileMenuCover>
    <List>
      {links &&
        links.map((link: Link, index: number) => (
          <ListItem key={index}>
            <LinkDecider {...link} />
            {link.nestedLinks.length > 0 && <NestedLinks {...link} />}
          </ListItem>
        ))}
    </List>
  </Wrapper>
);

export default () => {
  const data = useStaticQuery(graphql`
    {
      navbar: navbarJson {
        logo
        links {
          url
          text
          isExternal
          nestedLinks {
            isExternal
            text
            url
          }
        }
      }
    }
  `);
  return <NavbarComponent {...data.navbar} />;
};
