import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link as GatsbyLink } from "gatsby";

const Wrapper = styled.div`
  box-shadow: 0px 0px 6px rgba(50, 50, 50, 0.85);
  border: 3px solid #ffffff;
  display: flex;
  max-width: 800px;
  flex-direction: column;
  text-decoration: none;
`;

const Title = styled.div<{ color: string }>`
  border: solid 2px ${(props) => props.color};
  padding: 4px 10px;
  background-color: ${(props) => props.color};
  font-weight: normal;
  font-size: 1.1rem;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 0 1px 0;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: solid 1px #dddddd;
  & li {
    border-bottom: solid 1px #dddddd;
    &:last-child {
      border-bottom: none;
    }
  }
`;

const LinkCSS = css`
  background: #ffffff;
  width: 100%;
  display: block;
  position: relative;
  padding: 8px 10px 8px 30px;
  margin: auto;
  color: inherit;
  text-decoration: none;
  font-size: 0.9rem;
  &:hover {
    background-color: #e9f6d8;
  }
  &::before {
    content: " ";
    display: block;
    position: absolute;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #b9b5a9;
    top: 50%;
    margin-top: -5px;
    left: 12px;
  }
`;

const InternalLink = styled((props) => <GatsbyLink {...props} />)`
  ${LinkCSS}
`;

const ExternalLink = styled.a`
  ${LinkCSS}
`;

export type Link = {
  text: string;
  url: string;
  isExternal: boolean;
};

export type LinkBoxProps = {
  title: string;
  color: string;
  links: Link[];
};

export default ({ title, color, links }: LinkBoxProps) => (
  <Wrapper>
    <Title color={color}>{title}</Title>
    <List>
      {links &&
        links.map((link: Link, index: number) => (
          <li key={index}>
            {link.isExternal ? (
              <ExternalLink href={link.url} target="_blank" rel="noopener">
                {link.text}
              </ExternalLink>
            ) : (
              <InternalLink to={link.url}>{link.text}</InternalLink>
            )}
          </li>
        ))}
    </List>
  </Wrapper>
);
