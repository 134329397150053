import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";

import { darkGreen, lightGreen } from "../../utils/colors";

const paleGreen = "#a5cca5";

const Wrapper = styled.footer`
  background-color: ${darkGreen};
  border-top: 24px solid ${lightGreen};
  color: ${paleGreen};
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
  padding: 28px;
  width: 100%;
`;

const Left = styled.div`
  flex: 3;
  flex-basis: 400px;
`;

const Right = styled.div`
  flex: 1;
  min-width: 162px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: inline-block;
  border-right: solid 1px ${paleGreen};
  margin: 5px 0;
  &:last-child {
    border: none;
  }
  &:hover {
    color: white;
  }
`;

const LinkCSS = css`
  padding: 0 10px;
  text-decoration: underline;
  color: inherit;
`;

const InternalLink = styled((props) => <GatsbyLink {...props} />)`
  ${LinkCSS}
`;

const ExternalLink = styled.a`
  ${LinkCSS}
`;

const Link = ({ isExternal, url, text }) => {
  return isExternal ? (
    <ExternalLink target="_blank" rel="noopener" href={url}>
      {text}
    </ExternalLink>
  ) : (
    <InternalLink to={url}>{text}</InternalLink>
  );
};

type Link = {
  text: string;
  url: string;
  isExternal: boolean;
};
export type FooterProps = {
  copyright: string;
  links: Link[];
  telephone: string;
};

export const FooterComponent = ({ copyright, links, telephone }) => (
  <Wrapper>
    <Left>
      {copyright}
      <List>
        {links.map((link, index) => (
          <ListItem key={index}>
            <Link {...link} />
          </ListItem>
        ))}
      </List>
    </Left>
    <Right>{telephone}</Right>
  </Wrapper>
);

export default () => {
  const data = useStaticQuery(graphql`
    {
      footer: footerJson {
        copyright
        links {
          text
          url
          isExternal
        }
        telephone
      }
    }
  `);
  return <FooterComponent {...data.footer} />;
};
